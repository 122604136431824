
import {Tokenstorage} from "./library/tokenstorage";
import {NotarisdossierApi} from "./library/notarisdossierApi";
import {Clusterconfig} from "./library/clusterconfig";
import {MailParser} from "./library/mailParser";


/**
 * Some global variables for the state of the add-in when the taskpane is loaded
 */
class AppState {
  static version = 'Build 43';
  static debugMode = false;
  static configLoaded = false;
  static messageFilenameFormat = null;
  static maxUploadSize = 10485760;
}


/**
 * This is the Office.onReady method which is executed after the addin has finished loading. Make sure that we don't
 * execute code outside this method.
 */
Office.onReady((info) => {
  if (info.host === Office.HostType.Outlook) {

    // Update layout
    uiUpdate();

    // Init select2
    initSelectFile();

    // Add handlers
    $('#btn-select-cluster').on('click', btnSelectClusterClick);
    $('#btn-reset-cluster').on('click', btnLogoutClick);
    $('#btn-logout').on('click', btnLogoutClick);
    $('#btn-save-email').on('click', btnSaveMailClick);
    $('#switch-check-attachments').on('change', btnCheckAttachments)
    $('#btn-toggle-debug').on('click', btnToggleDebugClick);
    $('#btn-refresh-token').on('click', btnRefreshTokensClick);
    $('#form-login').on('submit', formLoginSubmit);
    $('#div-log').on('click', clearLog);
    $('#cb-save-eml-message').on('change', uiUpdateSaveMailButton);
    $('#div-office').hide();

    // Update layout when a new message is selected
    Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, function () {
      logMessage('Mailbox.ItemChanged event');
      uiUpdate();
    });
  }
});


/**
 * Toggle attachments checked
 */
function btnCheckAttachments() {
  logMessage('TaskPane.btnCheckAttachments()');
  let switchAttachmentsChecked = $('#switch-check-attachments:checked').length > 0;
  $('.cb-attachment').prop('checked', switchAttachmentsChecked);
}


/**
 * Toggle debug mode
 */
function btnToggleDebugClick() {
  AppState.debugMode = !AppState.debugMode;
  clearLog();
  let diagnostics = Office.context.diagnostics;
  logMessage('Diagnostics: ' + diagnostics.platform + ' '  + diagnostics.host + ' ' + diagnostics.version);
  logMessage('Version: ' + AppState.version);
  uiUpdate();
}


/**
 * General Exception handler, easy way to deal with errors.
 * Use this like: .catch((error) {generalExceptionHandler(error);});
 *
 * @param error with optional status and message
 */
function generalExceptionHandler(error)
{
  if (error.status !== undefined && error.status == 401) {
    error.message = 'Fout bij opnieuw inloggen';
    btnLogoutClick();
  }
  if (error.message !== undefined) {
    uiShowError(error.message);
  } else {
    uiShowError(error);
  }
}


/**
 * button for refreshtokens with automated error handling.
 */
function btnRefreshTokensClick()
{
  NotarisdossierApi.refreshTokens()
    .catch((error) => {
      error = {
        status: error.status,
        message: 'Fout bij opnieuw inloggen'
      };
      generalExceptionHandler(error);
    });
}


/**
 * Log a message (also to console). Outlook does not support the console (except the web version) and we need to be
 * able to trace problems.
 *
 * @param msg
 */
export function logMessage(msg) {
  console.log(msg);

  let currentLog = $('#div-log').html();
  let messages = currentLog.split('<br>');
  if (currentLog === '') {
    messages = [];
  }
  messages.push(msg);
  if (messages.length > 20) {
    messages.shift();
  }
  $('#div-log').html(messages.join('<br>'));
}


/**
 * Minimal Mailbox API version we address to use
 * @returns {boolean}
 */
export function isMailboxAPISupported() {
  return Office.context.requirements.isSetSupported('Mailbox', '1.8');
}


/**
 * Clear the log
 */
function clearLog() {
  $('#div-log').html('');
}

/**
 * Get AppState Version
 * @returns {string}
 */
export function getAppStateVersion() {
  return AppState.version;
}

/**
 * Get diagnostics
 * @returns {string}
 */
export function getDiagnostics() {
  let diagnostics = Office.context.diagnostics;
  return 'Supported: ' + isMailboxAPISupported() + ', ' + diagnostics.platform + ' ' +
      diagnostics.host + ' ' + diagnostics.version;
}


/**
 * Load the clusters from config
 */
function populateSelectCluster() {
  const clusters = Clusterconfig.getUrls(AppState.debugMode);
  $('#select-cluster-url').html('');
  clusters.forEach(function(cluster) {
    $('#select-cluster-url').append($('<option>', {value: cluster.href, text: cluster.label}));
  });
}

function populateOffice(offices) {
  $('#div-office').show();
  $('#select-office').html('');
  offices.forEach(function(office) {
    $('#select-office').append($('<option>', {value: office.id, text: office.name}));
  })
}


/**
 *
 */
function uiUpdate()
{
  // Hide all sections and messages
  $('section').hide();
  $('#div-error').hide();
  $('#div-warning').hide();
  $('#div-success').hide();
  $('#div-loader').hide();

  // Get the current date and time
  var currentDate = Date.now();
  // Create a date object for January 16, 2025
  var targetDate = new Date('2025-01-16').getTime();

  if (currentDate > targetDate) {
    $('#div-6-januari').hide();
  }

  if (AppState.debugMode) {
    $('.debug').show();
  } else {
    $('.debug').hide();
  }

  // Check if we need to select the cluster
  if (!Clusterconfig.isSet()) {
    logMessage('User needs to select cluster');
    populateSelectCluster();
    $('#section-select-cluster').show();
    return;
  }

  // Check if we need to login
  if (!Tokenstorage.hasTokens()) {
    logMessage('User needs to authenticate');
    $('#section-login').show();
    return;
  }

  // We're ready to save some messages :-)
  if (!AppState.configLoaded) {
    loadConfig();
  };
  uiRefreshMessage();
  uiUpdateSaveMailButton();
  $('#section-save-message').show();
}


/**
 * The save mail button is only enabled when there is a file selected and at least one checkbox is checked
 */
function uiUpdateSaveMailButton() {
  let fileIsSelected = $('#select-file').val() !== null;
  let saveEmlIsChecked = $('#cb-save-eml-message').prop('checked');
  let saveAttachmentIsChecked = $('.cb-attachment:checked').length > 0;
  if (fileIsSelected && (saveEmlIsChecked || saveAttachmentIsChecked)) {
    $('#btn-save-email').removeAttr('disabled');
  } else {
    $('#btn-save-email').attr('disabled', 'disabled');
  };
}


/**
 * @param msg
 */
function uiShowError(msg)
{
  uiHideLoader();
  logMessage('Show error message: ' + msg);
  $('#span-error-message').text(msg);
  $('#div-error').show();
  setTimeout(function () {
    $('#div-error').hide();
  }, 6000);
}


/**
 * @param msg
 * @param timer
 */
function uiShowWarning(msg, timer = true)
{
  uiHideLoader();
  logMessage('Show warning message: ' + msg);
  $('#span-warning-message').text(msg);
  $('#div-warning').show();
  if (timer) {
    setTimeout(function () {
      $('#div-warning').hide();
    }, 6000);
  }
}


/**
 * @param msg
 */
function uiShowSuccess(msg)
{
  uiHideLoader();
  logMessage('Show success message: ' + msg);
  $('#span-success-message').text(msg);
  $('#div-success').show();
  setTimeout(function () {
    $('#div-success').hide();
  }, 4000);
}


/**
 * @param msg
 */
function uiShowLoader(msg)
{
  logMessage('Show loader with message: ' + msg);
  $('#span-loader-message').text(msg);
  $('#div-loader').show();
}

/**
 * Hide the loader
 */
function uiHideLoader()
{
  $('#div-loader').hide();
}



/**
 * When a new message is selected we need to update the details of the message and attachments
 */
function uiRefreshMessage(uploadSizeTooBig = false)
{
  const mailItem = Office.context.mailbox.item;

  // Update the select2 box
  updateFileSelectWithSubject(mailItem.subject);

  // We can only show the e-mail name when the config is loaded - don't worry, the layout is updated after the
  // config is loaded so it will only be hidden for a couple of ms.
  $('#cb-save-eml-message').prop('checked', true);
  $('#switch-check-attachments').prop('checked', false);
  if (AppState.configLoaded) {
    const userProfile = Office.context.mailbox.userProfile;
    let fileName = MailParser.getEmlFilename(AppState.messageFilenameFormat, mailItem, userProfile);
    $('#email-file-name')[0].oldValue = '';
    $('#email-file-name').val(fileName.replace(/.eml/, ''));

    $('input[name="email-file-name"]').inputFilter(function(value) {
      return /^(?! )[a-zA-Z0-9\]\[().,;:_ -]*$/.test(value); }, "Gebruik alleen tekens die zijn toegestaan");
  }

  // Load all non-inline attachments as checkbox. Those are not checked by default.
  let numberOfAttachments = 0;
  let byteSizeOfAttachments = 0;
  $('#div-attachments').html('');
  mailItem.attachments.forEach((att) => {
    if (att.contentType !== 'message/rfc822') {
      byteSizeOfAttachments += att.size;
    }
    if (!att.isInline) {
      let attName = '';
      if (att.name.length > 50) {
        attName = att.name.substring(0, 23) + ' ...' + att.name.substring(att.name.length - 10);
      } else {
        attName = att.name;
      }
      let id = '';
      if (att.contentType !== 'message/rfc822') {
        id = 'cb-save-att-' + ++numberOfAttachments;
      }
      let icon = MailParser.getAttachmentIcon(att.contentType);
      let cbTemplate = '';
      if (att.contentType === 'message/rfc822') {
        cbTemplate =
            '<div class="form-check">' +
            '    <i class="bi bi-exclamation-triangle-fill text-danger"></i>' +
            '    <span class="form-check-label text-break">' + attName
        '    </span>' +
        '</div>';
      } else {
        cbTemplate =
            '<div class="form-check">' +
            '    <input class="form-check-input cb-attachment" type="checkbox" data-id="' + att.id + '" id="' + id + '">' +
            '    <label class="form-check-label text-break" for="' + id + '">' +
            '        <i class="bi ' + icon.class + '" style="color: ' + icon.color + ';"></i> ' + attName
        '    </label>' +
        '</div>';
      }
      $('#div-attachments').append(cbTemplate);
      $('.cb-attachment').on('change', uiUpdateSaveMailButton);
    }
  });

  if (byteSizeOfAttachments > AppState.maxUploadSize || uploadSizeTooBig) {
    $('#header-eml-file').text('E-mail (exclusief bijlagen)');

    let warningTemplate =
      '<div class="alert alert-warning" role="alert" style="margin-bottom: 5px;">' +
      '   <i class="bi bi-exclamation-triangle-fill"></i>' +
        '<span> De e-mail is te groot om met bijlage(n) op te slaan. Selecteer de bijlage(n) om deze los op te slaan.</span>' +
      '</div>';
    $('#warning-eml-file').html(warningTemplate);

    $('#cb-save-eml-message').data('with-attachments', false);
    $('.cb-attachment').prop('checked', true);
    $('#switch-check-attachments').prop('checked', true);
  } else {
    $('#warning-eml-file').html('');
    $('#header-eml-file').text('E-mail (inclusief bijlagen)');
    $('#cb-save-eml-message').data('with-attachments', true);
  }


  // Do not show the checkboxes to select if we want to save the e-mail or attachments if there are no attachments
  if (numberOfAttachments === 0) {
    $('#div-attachements-container').hide();
    $('#td-save-eml-message').hide();
    $('#div-switch-check-attachments').hide();
  } else if (numberOfAttachments === 1) {
    $('#div-attachements-container').show();
    $('#td-save-eml-message').show();
    $('#div-switch-check-attachments').hide();
  } else {
    $('#div-attachements-container').show();
    $('#td-save-eml-message').show();
    $('#div-switch-check-attachments').show();
  }
}


/**
 * Click handler for the select cluster
 */
function btnSelectClusterClick()
{
  logMessage('btnSelectClusterClick()');
  let clusterUrl = $('#select-cluster-url').val();
  if (clusterUrl === '') {
    logMessage('Cluster is empty');
    uiShowError('Selecteer een cluster voordat je verder gaat');
  } else {
    Clusterconfig.setUrl(clusterUrl);
    uiUpdate();
  }
}


/**
 * Click handler for the logout button
 */
function btnLogoutClick()
{
  logMessage('btnLogoutClock()');
  Clusterconfig.clear();
  Tokenstorage.clearTokens();
  uiUpdate();
}


/**
 * Submit handler for the login form
 *
 * @param e
 * @returns {Promise<void>}
 */
async function formLoginSubmit(e)
{
  e.preventDefault();
  logMessage('formLoginSubmit');

  $('#form-login').validate({
    errorClass: 'text-danger',
    errorElement: 'small',
    errorPlacement: function(error, element) {
      error.appendTo(element.parent().parent())
    },
    rules: {
      username: {required: true, email: true},
      password: {required: true, minlength: 8, maxlength: 100},
    },
    messages: {
      username: {required: 'Gebruikersnaam is vereist', email: 'Voer een geldig e-mailadres in'},
      password: {required: 'Wachtwoord is vereist', minlength: 'Vul hier minimaal 8 tekens in', maxlength: 'Vul hier maximaal 100 tekens in'},
    },
  });

  if ($('#form-login').valid()) {
    logMessage('Login form is valid');
    uiShowLoader('Bezig met aanmelden');
    const username = $('input[name=username]').val();
    const password = $('input[name=password]').val();
    let office = null;
    if ($('#select-office').val() !== undefined) {
      office = $('#select-office').val();
    }

    NotarisdossierApi.authenticate(username, password, office).then(() => {
      try {
        MailParser.mailboxCreateCategoryIfNotExists(); // also check if the category is already created upon login
      } catch (e) {
        logMessage('Error: ' + e.message);
      }

      uiUpdate();
      uiShowSuccess('Ingelogd als ' + username);
    }).catch((error) => {
        if (error.hasOwnProperty('offices')) {
          populateOffice(error.offices);
          uiShowError('Selecteer een kantoor');
        } else if (error.hasOwnProperty('message')) {
          uiShowError(error.message);
        } else {
          uiShowError(error);
        }
    });
  }
}


/**
 * Load config. We perform 2 API calls - it is possible in one, but this is more convenient.
 */
function loadConfig()
{
  logMessage('loadConfig');
  NotarisdossierApi.getSettings(NotarisdossierApi.folderNameSettings).then((folders) => {
      let html = '';
      Object.values(folders).forEach((element) => html += '<option value="' + element + '">' + element + '</option>');
      $("#select-folder").html(html);
      NotarisdossierApi.getSettings(['save-message-filename-format']).then((format) => {
        AppState.messageFilenameFormat = Object.values(format)[0];
        AppState.configLoaded = true;
        uiUpdate();
      }).catch(() => {
        uiShowError('Fout bij ophalen config');
      });
  }).catch((error) => {
      error = {
        status: error.status,
        message: 'Fout bij ophalen mappen'
      };
      generalExceptionHandler(error);
    });
}


async function updateFileSelectWithSubject(subject) {
  logMessage('updateFileSelectWithSubject');
  NotarisdossierApi.searchFile(subject, true).then((json) => {
    if (json.length > 0) {
      var newOption = new Option(json[0].reference + " - " + json[0].name, json[0].file_uuid, true, true);
      $("#select-file").append(newOption).trigger('change');
    } else {
      logMessage('-> No matching file found');
    }
  }).catch((error) => {
    if (error.message !== undefined) {
      logMessage('Error: ' + error.message);
    } else {
      logMessage('Error: ' + error);
    }
  });
}

/**
 * Init the Select2 for the file. It needs to call the API when searching, so basically the entire transport function
 * is overridden.
 *
 * @returns {Promise<void>}
 */
async function initSelectFile()
{
  $('#select-file').select2({
    width: "100%",
    placeholder: "Zoek hier naar een dossier",
    minimumInputLength: 4,
    ajax: {
      quietMillis: 750,
      dataType: 'json',
      transport: async function (params, success, failure) {
        NotarisdossierApi.searchFile(params.data.term)
          .then((json) => {
            success(json);
          }).catch((error) => {
            generalExceptionHandler(error);
            failure();
          });
      },
      processResults: function (data) {
        let results = $.map(data, function (obj) {
           return {id: obj.file_uuid, text: obj.reference + ' - ' + obj.name};
        });
        return {
          results: results,
        };
      },
    },
  });
  $('#select-file').on('change', function() {
    uiUpdateSaveMailButton();
  });
}


/**
 * Handler for the save mail button.
 *
 * @returns {Promise<void>}
 */
async function btnSaveMailClick()
{
  logMessage('btnSaveMailClick()');
  $('#btn-save-email').attr('disabled', 'disabled');

  const mailItem = Office.context.mailbox.item;
  const userProfile = Office.context.mailbox.userProfile;
  const fileUuid = $('#select-file').val();
  let folderName = $('#select-folder').val();

  if (folderName.length < 1) {
    uiShowError('Selecteer een map om de e-mail op te slaan');
    uiUpdateSaveMailButton();
    return;
  }

  let fileName = $('#email-file-name').val();
  if (fileName.length <= 4 || fileName.toLowerCase() == 'bestandsnaam') {
    fileName = MailParser.getEmlFilename(AppState.messageFilenameFormat, mailItem, userProfile);
  } else {
    fileName = fileName + '.eml';
  }

  let attachments = [];
  if (MailParser.isLegacyOutlook()) {
    await Tokenstorage.refreshAttachmentTokenIfNeeded();
    attachments = await MailParser.legacyMailItemGetAttachmentsContent(mailItem);
  } else {
    attachments = await MailParser.mailItemGetAttachmentsContent(mailItem);
  }
  logMessage('btnSaveMailClick: attachments set');

  const saveEmlMessage = $('#cb-save-eml-message').prop('checked');

  let attachmentsIncludeMessageRfc822 = false;
  attachments.forEach((att) => {
    if (att.contentType === 'message/rfc822') {
      attachmentsIncludeMessageRfc822 = true;
    }
  });

  // Save EML file (includes attachments)
  if (saveEmlMessage) {
    uiShowLoader('Bezig met opslaan e-mail');
    const emlWithAttachments = $('#cb-save-eml-message').data('with-attachments');
    let eml = await MailParser.createEml(mailItem, attachments, emlWithAttachments);
    if (eml.length > AppState.maxUploadSize) {
      uiShowError('Mail is te groot, sla bestanden los op. Er is niets is opgeslagen');
      uiUpdateSaveMailButton();
      uiRefreshMessage(true);
      return;
    }

    NotarisdossierApi.addFileInternalDocument(fileUuid, fileName, folderName, eml, false).then(() => {
      uiShowSuccess('E-mail opgeslagen');
      uiUpdateSaveMailButton();
      try {
        MailParser.mailItemAddCategory(mailItem);
      } catch (e) {
        logMessage('Error: ' + e.message);
      }
    }).catch((e) => {
      e = {
        status: e.status,
        message: 'Fout bij opslaan e-mail',
      }
      generalExceptionHandler(e);
      uiUpdateSaveMailButton();
    });
  }

  // Save attachments. We've saved the attachment ID as data attribute on
  if (!saveEmlMessage) {
    if ($('.cb-attachment:checked').length > 1) {
      uiShowLoader('Bezig met opslaan bijlagen');
    } else {
      uiShowLoader('Bezig met opslaan bijlage');
    }
  }
  let numberOfErrors = 0;
  $('.cb-attachment:checked').each(function() {
    let checkbox = $(this);
    let attachmentId = checkbox.data('id');
    let index = attachments.findIndex((attachment) => attachment.id === attachmentId);
    let name = attachments[index].name;
    let content = attachments[index].content;
    NotarisdossierApi.addFileInternalDocument(fileUuid, name, folderName, content, true).then(() => {
      //
    }).catch(() => {
      numberOfErrors++;
      uiShowError('Fout bij opslaan bijlage "' + name + '"');
    });
  });
  if (!saveEmlMessage && numberOfErrors === 0) {
    if ($('.cb-attachment:checked').length > 1) {
      uiShowSuccess('Bijlagen opgeslagen');
    } else {
      uiShowSuccess('Bijlage opgeslagen');
    }
  }

  if (attachmentsIncludeMessageRfc822) {
    uiShowError('Bijlage(n) .eml worden niet opgeslagen! Download deze handmatig')
  }


}

/**
 * Function for validating inputs
 * @par callback function for validation
 * @par errMsg automated message above input
 *
 * Validators op inputs, te gebruiken door:
 * `veld`.inputFilter(function(value) { return `regex`.test(value); }, `errormessage`)
 */
(function($) {
  $.fn.inputFilter = function(callback, errMsg) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop focusout", function(e) {
      if (callback(this.value)) {
        // Accepted value
        if (["keydown","mousedown","focusout"].indexOf(e.type) >= 0){
          this.setCustomValidity("");
        }
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        // Rejected value - restore the previous one
        this.setCustomValidity(errMsg);
        this.reportValidity();
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        // Rejected value - nothing to restore
        this.value = "";
      }
    });
  };
}(jQuery));

